import { getScenario, getScenarioType, uuidv4 } from "./localStorage.service"

let IS_DEV =
  window.location.href.includes("localhost") ||
  window.location.href.includes("127.0.0.1") ||
  window.location.href.includes("development")
const IS_STAGING = window.location.href.includes("staging")
const IS_PROD = IS_DEV === false && IS_STAGING === false

if (IS_DEV === false && IS_STAGING === false && IS_PROD === false) {
  //* last fallback to choose environment
  IS_DEV = true
}

const baseUrl = IS_PROD
  ? process.env?.REACT_APP_BASE_API_PROD_URL ??
    "https://digital-friend-api-prod.azurewebsites.net"
  : IS_STAGING
  ? process.env?.REACT_APP_BASE_API_STAGING_URL ??
    "https://digital-friend-api-staging.azurewebsites.net"
  : process.env?.REACT_APP_BASE_API_DEV_URL ??
    "https://digital-friend-api-dev.azurewebsites.net"

// const chatDevApiKey = "xcysw9kx78"
// const voiceDevApiKey = "nX7g9sdzbjrpZmC"

const apiKey = IS_PROD
  ? process.env?.REACT_APP_API_KEY_PROD ?? "nX7g9sdzbjrpZmC"
  : IS_STAGING
  ? process.env?.REACT_APP_API_KEY_STAGING ?? "nX7g9sdzbjrpZmC"
  : process.env?.REACT_APP_API_KEY_DEV ?? "nX7g9sdzbjrpZmC"

const baseSettings = {
  headers: {
    "X-Api-Key": `${apiKey}`,
    "Content-Type": "application/json",
    accept: "application/json",
  },
}

const baseBodyParams = () => {
  return {
    type: getScenarioType() ?? "customer",
    scenario: getScenario() ?? "allergy",
  }
}

let UNIQUE_IDENTIFIER = ""

let tonality = `${process.env?.REACT_APP_TONALITY ?? "professional"}`

/**
 * Sets the tonality of the chat service.
 * @param t The tonality to set. Must be one of "friendly", "professional", or "hopeful".
 */
export const setTonality = (t: "friendly" | "professional") => {
  tonality = t
}

export const textToSpeech = async (text: any) => {
  if (!text) throw new Error("Empty text")

  const useOpenAiVoice = localStorage.getItem("openAiVoice") == "true"
  const url =
    IS_PROD === true
      ? `${
          process.env?.REACT_APP_SYMANTO_T2S_API_PROD_URL ??
          "https://symanto-t2s-api-prod.azurewebsites.net"
        }/api/TextToSpeech?${useOpenAiVoice === true ? "model=OpenAi" : ""}`
      : `${
          process.env?.REACT_APP_SYMANTO_T2S_API_DEV_URL ??
          "https://symanto-t2s-api-dev.azurewebsites.net"
        }/api/TextToSpeech?${useOpenAiVoice === true ? "model=OpenAi" : ""}`

  return await fetch(url, {
    method: "POST",
    headers: {
      "X-Api-Key": `${
        IS_PROD
          ? process.env?.REACT_APP_SYMANTO_T2S_API_KEY_PROD ??
            "z1x2c3v4b5n6m7l8k9"
          : process.env?.REACT_APP_SYMANTO_T2S_API_KEY_DEV ??
            "z1x2c3v4b5n6m7l8k9"
      }`,
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      textToSpeech:
        `<prosody rate="${process.env?.REACT_APP_T2S_RATE ?? "1.1"}" pitch="${
          process.env?.REACT_APP_T2S_PITCH ?? "-5%"
        }" volume="${process.env?.REACT_APP_T2S_VOLUME ?? "soft"}">` +
        text +
        "</prosody>",
      modelName: `${
        process.env?.REACT_APP_MODEL_NAME ?? "de-DE-SeraphinaMultilingualNeural"
      }`,
      tone: tonality,
    }),
  }).then((r) => r.blob())
}

export const telemetry = async (data: {
  interaction_ai: number
  interaction_user: number
  duration: number
  audio: Blob
}) => {
  const formData = new FormData()
  formData.append("audio", data.audio, "audio.wav")
  return await fetch(
    `${baseUrl}/telemetry?interaction_ai=${data.interaction_ai}&interaction_user=${data.interaction_user}&duration=${data.duration}&session_id=${UNIQUE_IDENTIFIER}`,
    {
      headers: {
        "X-Api-Key": baseSettings.headers["X-Api-Key"],
      },
      method: "POST",
      body: formData,
    },
  ).then((r) => r.text())
}

export const startChat = async (
  language: string,
  prompIdQueryParam?: string | null,
) => {
  const _ID = uuidv4()
  UNIQUE_IDENTIFIER = _ID

  let url = baseUrl + `/start?language=${language}`

  if (process.env?.REACT_APP_USE_TOOLS !== undefined) {
    const useTools = process.env.REACT_APP_USE_TOOLS === "true"
    url += `&useTools=${useTools}`
  }

  if (process.env?.REACT_APP_PHONE !== undefined) {
    const phone = process.env.REACT_APP_PHONE
    url += `&phone=${phone}`
  }

  if (prompIdQueryParam?.length) {
    url += `&promptId=${prompIdQueryParam}`
  } else if (process.env?.REACT_APP_PROMPT_ID !== undefined) {
    const promptId = process.env.REACT_APP_PROMPT_ID
    url += `&promptId=${promptId}`
  }

  return await fetch(url, {
    ...baseSettings,
    method: "POST",
    body: JSON.stringify({
      ...baseBodyParams(),
      session_id: UNIQUE_IDENTIFIER,
    }),
  })
    .then((r) => r.json())
    .then((r) => {
      try {
        UNIQUE_IDENTIFIER = r.id
      } catch (e) {
        UNIQUE_IDENTIFIER = ""
        console.error(e)
      }
      return r
    })
}

export const insertMessageApi = async (text: string) => {
  if (!UNIQUE_IDENTIFIER) throw new Error("No conversation ID provided")

  const r = await fetch(baseUrl + `/${UNIQUE_IDENTIFIER}`, {
    ...baseSettings,
    method: "PUT",
    body: JSON.stringify({
      content: text,
    }),
  })
  return await r.json()
}
