const session_id_key = "sid";
const SCENARIO_KEY = "scenario";
const SCENARIO_TYPE_KEY = "scenario_type";

export const uuidv4 = () => {
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0,
			v = c == "x" ? r : (r & 0x3) | 0x8;
		return v.toString(16);
	});
};

export const generateAndStoreSidInLocalStorage = () => {
	localStorage.setItem(session_id_key, uuidv4());
};


export const getSIDFromLocalStorage = () => {
	let _v: string | null = localStorage.getItem(session_id_key);
	if (_v === undefined || _v === null) {
		generateAndStoreSidInLocalStorage();
		_v = localStorage.getItem(session_id_key) as string;
	}
	return _v;
};

export const getScenario = () => {
	return localStorage.getItem(SCENARIO_KEY);
}

export const setScenario = (scenario: string) => {
	localStorage.setItem(SCENARIO_KEY, scenario)
}

export const getScenarioType = () => {
	return localStorage.getItem(SCENARIO_TYPE_KEY);
}

export const setScenarioType = (scenarioType: string) => {
	localStorage.setItem(SCENARIO_TYPE_KEY, scenarioType)
}