import { hookstate, useHookstate } from "@hookstate/core"
import { setPreferredLanguage } from "../helpers/helper"

export interface IChatStateModel {
  isMicActive: boolean
  language: string
  chatMessages: IMessage[]
}

export interface IMessage {
  id: string
  conversationId: number
  messageAuthor: IMessageAuthor
  text: string
  date: string
  finalMessage?: boolean
  time?: string
}

export type IMessageAuthor = 0 | 1

const initialState = {
  isMicActive: false,
  language: "de",
  chatMessages: [],
}

const chatState = hookstate(<IChatStateModel>initialState)

const scrollLatestChat = () => {
  const d = document.querySelector("#chat")
  d?.scrollTo({ top: d.scrollHeight, behavior: "smooth" })
}

export function useChatState() {
  const state = useHookstate(chatState)

  return {
    get isMicActive() {
      return state.isMicActive.get()
    },
    get language() {
      return state.language.get()
    },
    get chatMessages() {
      return state.chatMessages.get()
    },

    setIsMicActive(t: boolean) {
      state.isMicActive.set(t)
    },
    setLanguage(t: string) {
      setPreferredLanguage(t)
      if (state.language.get() != t) {
        state.language.set(t)
      }
    },
    updateMessage(t: IMessage) {
      const exists = state.chatMessages.get().find((e) => e.id === t.id)
      if (!exists) {
        state.chatMessages.set((x) => {
          t.text = t.text.replaceAll(/<[^>]+>/g, "")
          x.push(t)
          return x
        })
      } else {
        state.chatMessages.set((m) => {
          return m.map((e) => {
            if (e.id === t.id) {
              t.text = t.text.replaceAll(/<[^>]+>/g, "")
              return t
            }
            return e
          })
        })
      }

      setTimeout(() => {
        scrollLatestChat()
      }, 300)
    },
    appendChatMessage(t: IMessage) {
      state.chatMessages.set((x) => {
        x.push({
          ...t,
          text: t.text.replaceAll(/<[^>]+>/g, ""),
        })
        return x
      })

      setTimeout(() => {
        scrollLatestChat()
      }, 300)
    },
    resetState() {
      state.isMicActive.set(false)
      state.language.set("de")
      state.chatMessages.set([])
    },
  }
}
