// import "./sentry.js"
import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"

import { DigitalFriend } from "./components/digitalfriend/DigitalFriend"
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
if (process.env?.REACT_APP_USE_BACKGROUND_IMAGE == "true") {
  const _rootDIv = document.getElementById("root") as HTMLElement
  _rootDIv.classList.add("bg-image")
}

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route path="" element={<DigitalFriend />} />
        <Route path="*" element={<Navigate to="" replace />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>,
)
