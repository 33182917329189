import React from 'react'

export const MicrophoneActions = ({ isActive, onPlay, onStop }: { isActive: boolean, onPlay: () => void, onStop: () => void }) => {
  return (
    <>
      {
        isActive
          ? <button onClick={() => onStop()} className="w-8 h-8 opacity-30">
            <svg fill="#ffffff" height="100%" width="100%" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
              viewBox="0 0 512 512" xmlSpace="preserve">
              <path d="M256,0C114.617,0,0,114.615,0,256s114.617,256,256,256s256-114.615,256-256S397.383,0,256,0z M224,320 c0,8.836-7.164,16-16,16h-32c-8.836,0-16-7.164-16-16V192c0-8.836,7.164-16,16-16h32c8.836,0,16,7.164,16,16V320z M352,320 c0,8.836-7.164,16-16,16h-32c-8.836,0-16-7.164-16-16V192c0-8.836,7.164-16,16-16h32c8.836,0,16,7.164,16,16V320z" />
            </svg>
          </button>
          : <button onClick={() => onPlay()} className="w-8 h-8 opacity-30">
            <svg width="100%" height="100%" viewBox="-0.5 0 7 7" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
              <g id="Page-1" stroke="none" strokeWidth="1" fill="#ffffff" fillRule="evenodd">
                <g id="Dribbble-Light-Preview" transform="translate(-347.000000, -3766.000000)" fill="#ffffff">
                  <g id="icons" transform="translate(56.000000, 160.000000)">
                    <path d="M296.494737,3608.57322 L292.500752,3606.14219 C291.83208,3605.73542 291,3606.25002 291,3607.06891 L291,3611.93095 C291,3612.7509 291.83208,3613.26444 292.500752,3612.85767 L296.494737,3610.42771 C297.168421,3610.01774 297.168421,3608.98319 296.494737,3608.57322" id="play-[#1003]">
                    </path>
                  </g>
                </g>
              </g>
            </svg>
          </button>
      }
    </>
  )
}
