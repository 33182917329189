/** 
 * @returns "HH:MM:SS"
 */
export const differenceInTimeFromDates = (date1: Date | string | undefined | null, date2: Date | string | undefined | null) => {
	try {
		if (typeof date1 === "string" || !date1) date1 = date1 ? new Date(date1) : new Date();
		if (typeof date2 === "string" || !date2) date2 = date2 ? new Date(date2) : new Date();
		const time = new Date(date2.getTime() - date1.getTime()).toISOString().split("T")[1].split(".")[0];
		return time;
	} catch (e) {
		console.info(e);
		return "00:00:00";
	}
}


const PREFERRED_LANGUAGE_KEY = "preferredLanguage";

export const getPreferredLanguage = () => {
	return localStorage.getItem(PREFERRED_LANGUAGE_KEY) || "de-DE";
}

export const setPreferredLanguage = (language: string) => {
	localStorage.setItem(PREFERRED_LANGUAGE_KEY, language);
}